import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  width: 95%;
  padding: 0;
  margin: 0 auto;
`

const StyledUnderHero = styled.section`
  padding: 20px 0 0 0;
  text-align: center;

  ${breakpoint.medium`
  padding: 40px 0 0 0;
  `}

	h1 {
		text-align: center;
		font-size: 32px;
		padding: 10px;

      ${breakpoint.medium`
		width: 100%;
		font-size: 42px;
		padding: 0;
		margin-bottom: 10px;
      `}
	 }


	.point {
		color: ${colors.purpleDark};
	 	text-align: center;
		font-weight: 600;
		line-height: 1em;
		margin-top: 1.4rem;
		margin-bottom: 4.8px!important;
		padding: 0 0.4rem;
		font-size: 14px;
		}

	.detail {
		color: ${colors.purpleDark};
		text-align: center;
		font-size: 14px;
		line-height: 1.4em;
		padding: 0 0.4rem;
		margin-bottom: 2rem;
	}

	.row {
		display: flex;
		margin-top: 40px;
		flex-direction: column;
	}
	
	.column {
		flex: 0;
		align-self: center;

		&.first {
			margin-top: 0;
			margin-bottom: 10px;
		}

		&.middle {
			flex: 1;
		}
				
		p {
			padding: 0;
			margin: 0 auto 1rem !important;
		}
	}
		
	.columnIcons {
		display: grid;
		grid-auto-flow: row;
		rid-gap: 20px;
		width: 100%;
		max-width: 1200px;

		& > div {
			width: 100% !important;
		  }

		${breakpoint.medium`
			grid-auto-flow: column;
			& > div {
				width: 75% !important;
			  }
			`}


	}

      a {
   		color: ${colors.orange};   
      }
      
      a:hover {
   		color: ${colors.purpleDark};   
      }
	}

  
`

const UnderHero = props => {
	return (
		<StyledUnderHero>
			<StyledContainer>
				<div className="row">
					<div className="column first" data-aos="fade-up">
						<h1>Sexual Health Starter Pack</h1>
						<p>Inside the comprehensive starter pack, you’ll find everything you need to get started with the <br />Visby Medical Sexual Health Test:</p>
					</div>
					<div className="columnIcons">
						<div data-aos="fade-up">
							<StaticImage
								src="../../../assets/icons/pph-2023/device.png"
								width={105}
								alt="device"
							/>
							<p className="point">2 Cases</p>
							<p className="detail">Visby Medical Sexual Health Test (40 devices)</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="200">
							<StaticImage
								src="../../../assets/icons/pph-2023/pack.png"
								width={95}
								alt="device"
							/>
							<p className="point">1 Pack</p>
							<p className="detail">Visby Medical Vaginal Self Collection kits (50 kits)</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="400">
							<StaticImage
								src="../../../assets/icons/pph-2023/plug.png"
								width={100}
								alt="device"
							/>
							<p className="point">1</p>
							<p className="detail">Visby Medical Power Adaptor</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="600">
							<StaticImage
								src="../../../assets/icons/pph-2023/controls.png"
								width={90}
								alt="device"
							/>
							<p className="point">1 Pack</p>
							<p className="detail">Zeptometrix External Controls (6 positive, 6 negative)</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="600">
							<StaticImage
								src="../../../assets/icons/pph-2023/video.png"
								width={80}
								alt="video"
							/>
							<p className="point">30 Minutes</p>
							<p className="detail">Provider education session</p>
						</div>
						<div data-aos="fade-up" data-aos-delay="600">
							<StaticImage
								src="../../../assets/icons/pph-2023/video.png"
								width={80}
								alt="video"
							/>
							<p className="point">30 Minutes</p>
							<p className="detail">End user training session</p>
						</div>
					</div>
				</div>
			</StyledContainer>
		</StyledUnderHero>
	)
}

export default UnderHero
